.pagination {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pagination li {
    list-style: none;
  }
  
  .pagination .page-item.disabled .page-link {
    pointer-events: none;
    color: #6c757d;
    background-color: #fff;
    border-color: #dee2e6;
  }
  
  .pagination .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .pagination .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25
  }  