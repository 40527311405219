.account-searchAddContainer {
    position: relative;
    padding-right: 40px;
}

.account-inputSearch {
    padding: 10px 40px 10px 20px;
    border: solid 1px #000;
    font-size: 17px;
    width: 250px;
    border-radius: 5px;
}

.account-btnSearch {
    background: none;
    position: absolute;
    top: 0;
    right: 35px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.account-EditIcon {
    cursor: pointer;
    color: #0d6efd;
}

/* modal add */
.account-wrapItemAdd {
    padding: 10px;
}

.account-AddInput {
    width: 100%;
    padding: 3px 5px;
}

.account-AddError {
    color: red;
}

.account-AddInputCheckbox {
    width: 24px;
    height: 24px;
}

.account-wrapSelectStatus {
    border: solid 1px #000;
    padding: 10px 35px 10px 10px;
    border-radius: 5px;
}

.account-btnAdd {
    background: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    border: solid 1px #000;

}