.serviceQuote-wrapBtnExport {
  margin-left: 20px;
}

.serviceQuote-BtnExport {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px 20px;
}
.serviceQuote-WrapDateStart,
.serviceQuote-WrapDateEnd {
  display: flex;
  margin-right: 20px;
}
.serviceQuote-titleDate {
  margin-right: 10px;
}
.serviceQuote-inputDate {
  border-radius: 5px;
  border: 1px solid #000;
  padding: 10px 8px;
}
.serviceQuote-searchAddContainer {
    position: relative;
    margin: auto 0;
    padding-right: 40px;
}
.serviceQuote-inputSearch {
  padding: 10px 40px 10px 10px;
  border: solid 1px #000000;
  font-size: 17px;
  width: 250px;
  border-radius: 5px;
}
.serviceQuote-btnSearch {
    background: none;
    position: absolute;
    top: 0;
    right: 45px;
    padding: 10px;
    border: none;
    cursor: pointer;
}