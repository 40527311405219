.Home-WrapContent {
  position: fixed;
  left: 85px;
  top: 11vh;
  bottom: 0;
  padding-right: 75px;
  background-image: url("../../img/bg_2.png");
  background-size: cover;
  background-repeat: no-repeat;
}

/*L-desktop*/
@media (min-device-width: 1600px) {}

/*L-laptops*/
@media only screen and (min-device-width: 1200px) and (max-device-width: 1440px) {
  body {
    font-size: 18px;
  }
}

/*laptops/tablets*/
@media only screen and (min-device-width: 576px) and (max-device-width: 1024px) {
  body {
    font-size: 16px;
  }
}

/*tablets*/
@media only screen and (min-device-width: 576px) and (max-device-width: 768px) {
  body {
    font-size: 30px;
  }
}

/*Smart phone*/
@media only screen and (min-device-width: 320px) and (max-device-width: 576px) {
  body {
    font-size: 30px;
  }


}

@media only screen and (max-device-width: 319px) {
  body {
    font-size: 30px;
  }


}

.margin-pagination {
  margin: 5px 0;
}

body {
  height: 100vh;
  background-image: url("../../img/bg_2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.middle {
  vertical-align: middle;
}

.pad-r {
  padding-right: 10px;
}

.flex-div {
  display: flex;
}

.fontW700 {
  font-weight: 700;
}

.border-tb {
  border: solid 1px #000000;
}

.clw {
  color: #fff;
}

.clb {
  color: black;
}

.center-lmh {
  margin: auto 0;
}

.center-lmh-1 {
  margin: auto;
}

.center-lmh-2 {
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.middle {
  vertical-align: middle;
}

.titleError {
  margin-top: 5px;
  font-size: 15px;
  color: #ff0000;
}

.font18 {
  font-size: 18px !important;
}

/* rain bow text */
.rainBow {
  background: linear-gradient(to right,
      #ff0000,
      #ffae00,
      #ffff00,
      #00ff00,
      #0000ff,
      #8b00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rainBow:hover {
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow 1s linear infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/*end rain bow text */

/* highlight text */
.text-effect {
  font-weight: normal;
  text-shadow: none;
  transition: all 0.1s ease-in-out;
}

.link-a {
  text-decoration: none;
  color: #1a9e2c;
}

.text-effect:hover {
  font-weight: bold;
  /* text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff6eff,
    0 0 30px #ff6eff, 0 0 40px #ff6eff, 0 0 50px #ff6eff, 0 0 75px #ff6eff; */
}

/* end highlight text*/

.table-cus {
  /* border: solid 1px ; */
}

.itemsPerPage {
  width: 70px;
}


/* share */
.home-contentPage-title {
  margin: 15px 0 20px 0;
  padding: 0 0 15px 20px;
  font-size: 30px;
  font-weight: 500;
  /* border-bottom: 1px solid #000; */
}

.color-1 {
  /* background-image: linear-gradient(to right, #ee9ca7, #ffdde1) !important; */
  background-image: linear-gradient(to right, #e7e9a0, #ffdde1) !important;
}

.color-2 {
  background-image: linear-gradient(to right, #c6ffdd, #fbd786, #f7797d) !important;
}

.color-3 {
  background-image: linear-gradient(to right, #fc5c7d, #6a82fb) !important;
}

.ExportExcelBtn {
  background: #1db431 !important;
  padding: 10px !important;
  font-size: 19px !important;
  font-weight: 400;
}

.ExportExcelBtn:hover {
  color: #fff;
  background: #189128 !important;
}

.table-thead{
  vertical-align: middle !important;
}
.table-tbody{
  background: #fff;
}