.LineChart-WrapChart{
    background: #f6f6f6;
    padding-right: 20px;
    overflow: auto;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
}
.LineChart-TitleNoteY{
  margin-left: 10px;
}

.LineChart-TitleNoteX{
  text-align: right;
}
.chart-container{
  margin-bottom: 20px;
}