.subCategory-checkbox{
  width: 24px;
  height: 24px;
}

.subCate-editModalInput{
  margin: 8px 0 15px 0;
}

.subCate-modalAddInfo{
  display: none;
}