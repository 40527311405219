.NavigationBarWrap {
  width: 260px;
  text-align: center;
  /* background: #fff; */
  overflow: auto;
  height: 89vh;
  border-right: 1px solid #ffffff;
  font-size: 30px;
  position: fixed;
  left: -175px;
  transition: all 0.3s ease;
  z-index: 1;
  background-image: url('../../img/bg_2.png');
    background-size: cover;
}
.Navigation-Hi{
  font-size: 20px;
  margin-top: 20px;
  color: #1bb33f;
  font-weight: 700;
}
.NavigationBarWrap.open {
  left: 0;
}

.NavigationBarMenu-icon {
  margin: auto;
  float: right;
  font-size: 36px;
  color: #000;
}

.NavigationBarMenu-titleList {
  width: 70%;
  font-size: 25px;
}

.NavigationBarMenuWrap {
  margin: 15px 0;
  cursor: pointer;
}

.NavigationBarMenu {
  display: inline-grid;

  width: 100%;
}
.NavigationBarMenu-fadedLine {
  background: #909090;
  height: 1px;
}
.Navigation-Link{
  text-decoration: none;
  color: #000;
}
/* -----------------------Mobile-------------------------- */
