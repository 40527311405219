.NavBarWrap{
    background: #fff;
    border-bottom: 1px solid #ffffff;
    height: 11vh;
    background-image: url('../../img/bg_2.png');
    background-size: cover;
}
.logoSunny{
    margin: 0 0 0 10px;
    height: 10vh;
}