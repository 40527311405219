.DashBoard-Filter {
    display: flex;
    margin: 20px 60px 0px 40px;
    width: 335px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    font-size: 24px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
    /* background-image: url('../../img/bg_2.png'); */
}

.DashBoard-inputDate {
    border-radius: 5px;
    padding: 5px 8px;
    border: none;
}

.DashBoard-titleDate {
    margin-right: 10px;
    font-weight: 500;
}

.DashBoard-inputDate:focus {
    border: none !important;
}

.DashBoard-Content {
    margin: 20px 20px 40px 20px;
    display: flex;
}

.DashBoard-WrapCard {
    text-align: center;
}

.DashBoard-Card {
    /* background: rgba(20, 176, 26, 0.5); */
    background: #fff;
    margin: 0 20px;
    padding: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
    /* box-shadow: 0 0 5px hsla( 0, 0%, 0%, .1 ); */
    /* background-image: url('../../img/bg_2.png'); */
}

.DashBoard-LineChart{
    padding: 0 40px 0 40px;
}

/*----------------------------------------------------  date time---------------------------------------------------- */
.month-year-picker {
    border: 1px solid #000000;
    padding: 5px;
    font-size: 18px;
    color: #333;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
}

.month-year-picker input {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 18px;
    color: #333;
    width: 100%;
}

.month-year-picker .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
}

.month-year-picker .react-datepicker__month-container {
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.react-datepicker__month-container{
    font-size: 18px;
}

.month-year-picker .react-datepicker__current-month,
.month-year-picker .react-datepicker__navigation--next,
.month-year-picker .react-datepicker__navigation--previous {
    color: #333;
}

.month-year-picker .react-datepicker__day-name,
.month-year-picker .react-datepicker__day,
.month-year-picker .react-datepicker__time-name {
    color: #333;
}

.month-year-picker .react-datepicker__day:hover {
    background-color: #fff;
}

.month-year-picker .react-datepicker__day--selected {
    background-color: #ccc;
    color: #fff;
}

.month-year-picker .react-datepicker__day--keyboard-selected {
    background-color: #999;
    color: #fff;
}

.month-year-picker .react-datepicker__month-text,
.month-year-picker .react-datepicker__year-text {
    font-size: 16px;
    color: #333;
}

.DashBoard-CardContent{
    font-size: 24px;
}