.Login-Wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;
  /* background-color: #d9d9d9; */
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 30px 10px 50px 10px;
  background-image: url("../../img/bg_2.png");
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
}

.form-group {
  margin-bottom: 1rem;
}

label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.btn {
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0069d9;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

.login-wrap-input {
  width: 80%;
  position: relative;
  display: inline-block;
}

.login-wrap-inputP {
  width: 80%;
  position: relative;
  display: inline-block;
}
.login-wrap-error{
  width: 80%;
  position: relative;
  display: inline-block;
}

.login-showpass-Icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.login-icon-input{
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.login-fmU{
  padding-left: 40px;
}
.login-fmP{
  padding-right: 40px;
  padding-left: 40px;
}

.login-title{
  margin-bottom: 40px;
}
.login-btn{
  width: 80%;
  border-radius: 10px !important;
  font-weight: bold;
  background: #ffffff;
  color: #000;
}
.login-btn:hover{
  background: #28a831; 
}
.login-input{
  border-radius: 10px;
  background: #b2c1b7;
}
/* .login-input:focus{
  background: #87a27d;
} */

/* ===========================Mobile=========================== */
.login-form-mb {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 30px 10px 50px 10px;
  font-size: 36px;
}
.login-title-mb{
  margin-bottom: 60px;
  font-size: 80px;
}
.login-fmU-mb{
  padding-left: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 36px;
}
.login-fmP-mb{
  padding: 40px 80px;
  font-size: 36px;
}

.login-icon-input-mb{
  position: absolute;
  top: 48%;
  left: 20px;
  transform: translateY(-50%);
}
.login-wrap-error-mb{
  width: 80%;
  position: relative;
  display: inline-block;
}
.login-error-mb{
  font-size: 36px;
  color: red;
}

.login-wrap-inputP-mb {
  width: 80%;
  position: relative;
  display: inline-block;
  margin-top: 20px;
}
.login-showpass-Icon-mb {
  position: absolute;
  top: 48%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}

.login-btn-mb{
  width: 80%;
  padding: 40px 0;
  font-size: 36px;
}
