.contentM-option {
  margin-bottom: 20px;
}

.contentM-wrapBtnAdd {
  margin-left: 30px;
}

.contentM-navbarRight {
  display: flex;
  margin-left: auto;
}

.contentM-searchAddContainer {
  position: relative;
  padding-right: 40px;
}

.contentM-inputSearch {
  padding: 10px 40px 10px 20px;
  border: solid 1px #000;
  font-size: 17px;
  width: 250px;
  border-radius: 5px;
}

.contentM-inputSearch:focus {
  outline: none;
}

.contentM-btnSearch {
  background: none;
  position: absolute;
  top: 0;
  right: 45px;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.contentM-btnAdd {
  border: solid 1px #000;
  border-radius: 5px;
  padding: 10px 20px;
  background: #fff;
}

.contentM-WrapTable {
  margin: 0 40px 0 30px;
  height: calc(89vh - 220px);
}

.contentM-Table thead {
  position: sticky;
  top: -1px;
  /* border: solid 1px #000; */
  background-image: linear-gradient(to right, #fbff78, #ffdde1)
}

.contentM-Table {
  border: solid 1px #000;
}

.contentM-navbarRight {}

.contentM-iconAction {
  color: #0d6efd;
  cursor: pointer;
}

.contentM-btnFilter {
  padding-right: 30px;
  margin: auto 0;
  cursor: pointer;
}

/* Modal filter */
.contentM-filterItem {
  margin-bottom: 25px;
}

.contentM-filterItemInput {
  padding: 2px 5px;
}

.contentM-filterItemSelect {
  padding: 4px 5px;
}

.contentM-filterItemTime {
  margin-top: 15px;
}

.contentM-filterItem-titleTime {
  font-style: italic;
}

.contentM-filterItemError {
  color: red;
}

.contentM-filter-Wrapbtn {
  margin: auto;
}

.contentM-btnAccept {
  padding: 5px 25px;
  margin: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  background: green;
  font-weight: 500;
}

.contentM-btnCancel {
  padding: 5px 25px;
  margin: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #e03636;
  font-weight: 500;
}

.contentM-btnCancel:hover {
  background: #bcbcbc;
  color: #000;
}

.contentM-filterItemPriority {
  width: 25px;
  height: 25px;
}

.contentM-iconPin {
  width: 22px;
  margin: 0 2px;
  cursor: pointer;
}