/* .category-wrapItemAdd-T {

} */
.category-wrapItemAdd {
    margin-top: 10px;
}

.category-AddInput {
    width: 100%;
    padding: 3px 5px;
}

.category-ItemAddTitle {
    margin: auto 0;
}

.category-filter-Wrapbtn {
    display: flex;
    margin-top: 25px;
}

.category-btnAccept {
    padding: 6px 15px;
    /* border: 1px solid #000; */
    border-radius: 5px;
    background: green;
    font-weight: 500;
}

.category-btnCancel {
    float: right;
    padding: 6px 15px;
    margin-left: auto;
    /* border: 1px solid #000; */
    border-radius: 5px;
    background: #e03636;
    font-weight: 500;
}

.category-btnCancel:hover {
    background: #bcbcbc;
    color: #000;
    /* border: solid 1px #000; */
}

.category-checkbox {
    width: 20px;
    height: 20px;
    margin: auto;
}

.category-wrapCheckbox {
    margin-left: 18px;
}
.category-wrapItemAdd-Check{
    margin-top: 15px;
}
.category-wrapInput{
    margin-top: 10px;
}