.addContent-title {
    font-style: italic;
    font-weight: 400;
}

.addContent-wrapDirectional {
    position: relative;
}

.addContent-directional {
    position: absolute;
    right: 40px;
    display: flex;
}

.addContent-btnDirectionalUp {
    font-size: 21px;
    margin: auto 10px auto 0;
    border: 1px solid;
    border-radius: 5px;
    padding: 5px 10px;
}

.addContent-btnDirectionalSave {
    font-size: 21px;
    margin: auto 0 auto 10px;
    /* border: 1px solid; */
    border: none;
    color: #fff;
    background: #25c025;
    border-radius: 5px;
    padding: 5px 10px;
}

.AddContent-WrapEditor {
    height: 78vh;
    display: flex;
}

.AddContent-CkEditor {
    overflow: auto;
    padding: 0 20px;
}

.ck-media__wrapper {
    width: 650px;
}

.AddContent-WrapSiteBar {
    overflow: auto;
    padding-right: 25px;
}

.AddContent-SiteBar {
    padding: 0 10px 25px 10px;
}

.AddContent-SiteBarTitle {
    font-size: 24px;
    font-weight: 500;
    margin: 10px 0 20px 0;
}

.AddContent-WrapMiniContent {}

.AddContent-wrapSelectLocation {
    padding: 5px 10px;
    border-radius: 5px;
    /* border: solid 1px #000; */
    /* width: 50%; */
}

.AddContent-wrapOption {
    margin-top: 20px;
}

.AddContent-SummaryTitle {
    margin: 20px 0 10px 0;
    font-weight: 500;
    font-size: 19px;
}

h1.ck-placeholder {
    display: none;
}

.AddContent-WrapEditorContentEn {
    margin-top: 30px;
}

.AddContent-EditorContentTitle {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
}

.AddContent-EditorContentWrapInput {
    display: flex;
    margin-bottom: 20px;
}

.AddContent-EditorContentTitleI {
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.AddContent-EditorContentWrapInputEnVn {
    margin-top: 20px;
}

.AddContent-EditorContentInputEnVn {
    font-weight: 500;
    margin-bottom: 10px;
}


/* CSS cho input chọn file ảnh */
.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f2f2f2;
    color: #333;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
    background-color: #e0e0e0;
}

.AddContent-IconCheckImgSub{
    margin-left: 10px;
    height: 50px;
}